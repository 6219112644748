import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';

// tslint:disable no-default-export
export default () => (
  <>
    <Helmet title="Home" />

    <Intro>
      Plyk is a Montreal-based innovation studio. We support our clients by 
      helping them build the aptitudes they need to be successful in an always-changing world.
    </Intro>

  </>
);
